<template>
  <div class="main">It Coming Soon. (pivot)</div>
</template>


<script>
import { makeDataProvider } from '../common.js'
export default {
  props: ['configuration', 'dataSource'],
  created() {
    console.log('========pivot==========', this)
  }
}
</script>